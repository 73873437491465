export const entityRoutes = {
    path: "/companies/:id/entity",
    to: "companies/:id/entity",
    icon: "mdi-account-multiple-outline",
    activeRoutes: [ "view_entity"],
    allow_children : false,
    showInMenu: true,
    name : "company_entity",
    order : 17,

    meta: {
      title: "company_entity",
      permissions: "companies-entity",
      needInfoCompany:true
    },
    component: () => import("@/views/companies/entity"),
    children: [
      {
        path: "/",
        component: () => import("@/views/companies/entity/view"),
        name: "view_entity",
        icon: "mdi-file-document-multiple-outline",
        allowed: true,
        meta: {
          title: "view_entity",
          permissions: "companies-entity_view"
  
        }
    
        },
    ],
  };
  