export const accountsRoutes = {
    path: "/companies/:id/accounts",
    to: "companies/:id/accounts",
    component: () => import("@/views/companies/accounts"),
    name: "company_accounts",
    icon: "mdi-account-multiple-outline",
    allowed: true,
    activeRoutes: ["record_accounts" , "view_account"],
    allow_children: false,
    showInMenu: true,
    order : 13,
    meta: {
        permissions : "companies-accounts",
        title : "company_accounts",
        needInfoCompany:false
    },
    children:[
        {
            path: "/",
            component: () => import("@/views/companies/accounts/record"),
            name: "record_accounts",
            icon: "mdi-file-document-multiple-outline",
            allowed: true,
            meta: {
                title: "record_accounts",
                permissions: "companies-accountss"
            }
        },
        {
            path: ":account_id/show",
            component: () => import("@/views/companies/accounts/view"),
            name: "view_account",
            icon: "mdi-file-document-multiple-outline",
            allowed: true,
            meta: {
                title: "view_account",
                permissions: "companies-accounts"
            }
        },
    ]
};