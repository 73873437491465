<template>
  <v-app>
    <Notification />
    <v-main >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import cookies from "js-cookie";

export default {
  name: "App",
  components: { Notification: () => import("@/components/Notification") },
  methods: {
    handleTheme() {
      const theme_name = cookies.get("theme") || "blue";
      const theme_mode = cookies.get("theme_mode") || "light";
      this.$store.dispatch("SetTheme", theme_name);
      this.$store.dispatch("SetThemeMode", theme_mode);
      if (cookies.get("theme_mode") === "dark") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },
  created() {
    this.handleTheme();
  }
  // watch: {
  //   $cookies: {
  //     handler(cookie) {
  //       if (cookie.get("theme_mode") === "dark") {
  //         this.$vuetify.theme.dark = true;
  //       } else {
  //         this.$vuetify.theme.dark = false;
  //       }
  //     },
  //     immediate: true
  //   }
  // }
};
</script>
