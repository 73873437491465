export const contactsRoutes = {
    path: "/companies/:id/contacts",
    to: "companies/:id/contacts",
    icon: "mdi-account-multiple-outline",
    activeRoutes: ["record_contacts" , "view_contacts"] ,
    allow_children: false,
    showInMenu: true,
    name: "contacts_companies",
    meta: {
        title: "view-contacts",
        permissions: "company-contacts",
        needInfoCompany:false
    },
    order : 16,
    component: () => import("@/views/companies/contacts"),
    children: [
        {
            path: "/",
            component: () => import("@/views/companies/contacts/record"),
            name: "record_contats",
            icon: "mdi-file-document-multiple-outline",
            allowed: true,
            meta: {
                title: "record_contacts",
                permissions: "companies-contacts_view"
            }
        },
        {
            path: ":contact_id/show",
            component: () => import("@/views/companies/contacts/view"),
            name: "view_contacts",
            icon: "mdi-file-document-multiple-outline",
            allowed: true,
            meta: {
                title: "view_contacts",
                permissions: "companies-contacts_view"
            }
        },
    ],
};
