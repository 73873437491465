export const contactPeopleRoutes = {
    path: "/companies/:id/contact-people",
    to: "companies/:id/contact-people",
    icon: "mdi-account-multiple-outline",
    activeRoutes: ["record_contact-people" , "view_contact-people"] ,
    allow_children: false,
    order : 15,
    showInMenu: true,
    name: "contact_people_companies",
    meta: {
        title: "view-contact-people",
        permissions: "companies-contact-people",
        needInfoCompany:false
    },
    component: () => import("@/views/companies/contact-people"),
    children: [
        {
            path: "/",
            component: () => import("@/views/companies/contact-people/record"),
            name: "record_contact-people",
            icon: "mdi-file-document-multiple-outline",
            allowed: true,
            meta: {
                title: "record_contact-people",
                permissions: "companies-contact-people_view"
            }
        },
        {
            path: ":contact_people_id/show",
            component: () => import("@/views/companies/contact-people/view"),
            name: "view_contact-people",
            icon: "mdi-file-document-multiple-outline",
            allowed: true,
            meta: {
                title: "view_contact-people",
                permissions: "companies-contact-people_view"
            }
        },
    ],
};
