export const invitationsRoutes = {
    path: "/companies/:id/invitations",
    to: "companies/:id/invitations",
    icon: "mdi-account-multiple-outline",
    activeRoutes: ["record_invitations" , "view_inviteation"] ,
    allow_children: false,
    showInMenu: true,
    order : 18,

    name: "invitations_companies",
    meta: {
        title: "companies",
        permissions: "companies-invitations",
        needInfoCompany:false
    },
    component: () => import("@/views/companies/invitations"),
    children: [
        {
            path: "/",
            component: () => import("@/views/companies/invitations/record"),
            name: "record_invitations",
            icon: "mdi-file-document-multiple-outline",
            allowed: true,
            meta: {
                title: "record_invitations",
                permissions: "companies-invitations_view"
            }
        },
        {
            path: ":invitation_id/show",
            component: () => import("@/views/companies/invitations/view"),
            name: "view_inviteation",
            icon: "mdi-file-document-multiple-outline",
            allowed: true,
            meta: {
                title: "view_invitation",
                   permissions: "companies-invitations_view"
            }
        },
    ],
};
