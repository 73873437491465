var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-16 px-3 company-layout"},[_c('v-list',{staticClass:"d-flex flex-row justify-center tabs transparent py-0",attrs:{"dense":""}},[_c('v-list-item-group',{staticClass:"d-flex flex-row",attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.getCompanyRoutes),function(item,i){return _c('div',{key:i},[(
            _vm.checkPermissionManually(item.meta.permissions).view &&
            (!item.meta.needInfoCompany ||
              (item.name == 'company_entity' && _vm.getCompany.isEntity) ||
              (item.name == 'company_personal' && _vm.getCompany.isPersonal))
          )?_c('v-list-item',{attrs:{"to":`/${item.to.split('/')[0]}/${_vm.$route.params.id}/${
            item.to.split('/')[2]
          }`}},[_c('v-icon',{staticClass:"me-2 ms-1",domProps:{"innerHTML":_vm._s(item.icon)}}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"tab-title me-1",domProps:{"textContent":_vm._s(_vm.$t(`topBar.${item.name}`))}})],1)],1):_vm._e()],1)}),0)],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }