export const bankAccountsRoutes = {
    path: "/companies/:id/bank-accounts",
    to: "companies/:id/bank-accounts",
    component: () => import("@/views/companies/bank-accounts"),
    name: "company_bank_accounts",
    icon: "mdi-account-multiple-outline",
    allowed: true,
    activeRoutes: ["record_bank_accounts" , "view_bank_accounts"],
    allow_children: false,
    showInMenu: true,
    order : 21,
    meta: {
        permissions : "companies-bank-accounts",
        title : "company_bank_accounts",
        needInfoCompany:false
    },
    children:[
        {
            path: "/",
            component: () => import("@/views/companies/bank-accounts/record"),
            name: "record_bank_accounts",
            icon: "mdi-file-document-multiple-outline",
            allowed: true,
            meta: {
                title: "record_bank_accounts",
                permissions: "companies-bank-accounts_view"
            }
        },
        {
            path: ":bank_account_id/show",
            component: () => import("@/views/companies/bank-accounts/view"),
            name: "view_bank_accounts",
            icon: "mdi-file-document-multiple-outline",
            allowed: true,
            meta: {
                title: "view_bank_accounts",
                permissions: "companies-bank-accounts_view"
            }
        },
    ]
};