export const invoicesRoutes = {
    path: "/companies/:id/invoices",
    to: "companies/:id/invoices",
    icon: "mdi-account-multiple-outline",
    activeRoutes: [ "view_invoices"],
    allow_children : false,
    showInMenu: true,
    name : "invoices",
    order : 22,
    meta: {
      title: "company_invoices",
      permissions: "companies",
    },
    component: () => import("@/views/companies/invoices"),
    children: [
      {
        path: "/",
        component: () => import("@/views/companies/invoices/record"),
        name: "record_invoices",
        icon: "mdi-file-document-multiple-outline",
        allowed: true,
        meta: {
          title: "record_invoices",
          permissions: "companies_view"
  
        }
    
        },
      {
        path: ":reference_number/show",
        component: () => import("@/views/companies/invoices/view"),
        name: "view_invoices",
        icon: "mdi-file-document-multiple-outline",
        allowed: true,
        meta: {
          title: "view_invoices",
          permissions: "companies_view"
  
        }
    
        },
    ],
  };
  