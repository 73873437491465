export const socialRoutes = {
    path: "/companies/:id/social",
    to: "companies/:id/social",
    icon: "mdi-account-multiple-outline",
    activeRoutes: [ "view_social"],
    allow_children : false,
    showInMenu: true,
    name : "social",
    order : 20,
    meta: {
      title: "company_social",
      permissions: "companies-social-contacts",
    },
    component: () => import("@/views/companies/social"),
    children: [
      {
        path: "/",
        component: () => import("@/views/companies/social/record"),
        name: "record_social",
        icon: "mdi-file-document-multiple-outline",
        allowed: true,
        meta: {
          title: "record_social",
          permissions: "companies-social-contacts_view"
  
        }
    
        },
      {
        path: ":social_id/show",
        component: () => import("@/views/companies/social/view"),
        name: "view_social",
        icon: "mdi-file-document-multiple-outline",
        allowed: true,
        meta: {
          title: "view_social",
          permissions: "companies-social-contacts_view"
  
        }
    
        },
    ],
  };
