export const phonesRoutes = {
    path: "/companies/:id/phones",
    to: "companies/:id/phones",
    icon: "mdi-account-multiple-outline",
    activeRoutes: [ "view_phones"],
    allow_children : false,
    showInMenu: true,
    name : "phones",
    order : 20,
    meta: {
      title: "company_phones",
      permissions: "companies-contact-phones",
    },
    component: () => import("@/views/companies/phones"),
    children: [
      {
        path: "/",
        component: () => import("@/views/companies/phones/record"),
        name: "record_phones",
        icon: "mdi-file-document-multiple-outline",
        allowed: true,
        meta: {
          title: "record_phones",
          permissions: "companies-contact-phones_view"
  
        }
    
        },
      {
        path: ":phone_id/show",
        component: () => import("@/views/companies/phones/view"),
        name: "view_phones",
        icon: "mdi-file-document-multiple-outline",
        allowed: true,
        meta: {
          title: "view_phones",
          permissions: "companies-contact-phones_view"
  
        }
    
        },
    ],
  };
